/* reset */
@font-face {
  font-family: 'Gwendolyn';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("/assets/fonts/Gwendolyn-Regular.ttf");
}

@font-face {
  font-family: 'Gwendolyn';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("/assets/fonts/Gwendolyn-Bold.ttf");
}

*, *::after, *::before {
  box-sizing: border-box;
}

* {
  font: inherit;
  margin: 0;
  padding: 0;
  border: 0;
}

:root {
  --text-w: #dee2e6;
  --accent: #B59736;
  --red-col: #AB3121;
  --red-col-hover: #C05A4D;

  --bs-body-bg: #FCFBFB !important;
  --bs-secondary-bg: #EFE9E9 !important;
  --bs-secondary-bg-rgb: 239, 233, 233 !important;
  --bs-tertiary-bg: #FBF5F5 !important;
  --text-shadow: #AB3121;
  --infobox-col: #E8E7E7;
}

:root[data-bs-theme=dark] {
  --bs-body-bg: #1C1C1C !important;
  --bs-secondary-bg: #403434 !important;
  --bs-secondary-bg-rgb: 64, 52, 52 !important;
  --bs-tertiary-bg: #310E0E !important;
  --text-shadow: #FCFBFB;
  --infobox-col: #342323;
}

.btn-primary {
  --bs-btn-color: var(--text-w) !important;
  --bs-btn-bg: var(--red-col) !important;
  --bs-btn-border-color: var(--red-col) !important;
  --bs-btn-hover-color: var(--text-w) !important;
  --bs-btn-hover-bg: var(--red-col-hover) !important;
  --bs-btn-hover-border-color: #DD7365 !important;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: var(--text-w) !important;
  --bs-btn-active-bg: #DD7365 !important;
  --bs-btn-active-border-color: #E06A5B !important;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: var(--text-w) !important;
  --bs-btn-disabled-bg: var(--red-col) !important;
  --bs-btn-disabled-border-color: var(--red-col) !important;
}

body {
  font-family: "Noto Sans Symbols", sans-serif !important;
  word-wrap: normal;
  overflow-wrap: break-word;
}

h1, h2, h3, h4 {
  font-weight: 400;
}

ol, ul, menu {
  list-style: none;
}

button, input, textarea, select {
  background-color: transparent;
  border-radius: 0;
  color: inherit;
  line-height: inherit;
  appearance: none;
}

textarea {
  resize: vertical;
  overflow: auto;
  vertical-align: top;
}

a {
  background: transparent;
}

.gwendolyn-regular {
  font-family: "Gwendolyn", cursive !important;
  font-weight: 400;
  font-style: normal;
}

.gwendolyn-bold {
  font-family: "Gwendolyn", cursive !important;
  font-weight: 700;
  font-style: normal;
}

.title-fonts {
  font-size: 60px;
  word-wrap: break-word;
}

.title-fonts-medium {
  font-size: 45px;
}

.subtitle-fonts {
  font-size: 30px;
}

.gold-style-text {
  --bg-size: 300%;
  --color-one: hsl(46, 54%, 46%);
  --color-two: hsl(50, 100%, 47%);
  background: linear-gradient(90deg, var(--color-one), var(--color-two), var(--color-one)) 0 0 / var(--bg-size) 100%;
  color: transparent !important;
  -webkit-background-clip: text;
  background-clip: text;
}

@media (prefers-reduced-motion: no-preference) {
  .gold-style-text {
    animation: move-bg 4.5s linear infinite;
  }

  @keyframes move-bg {
    to {
      background-position: var(--bg-size) 0;
    }
  }
}

.url-red {
  color: var(--red-col) !important;
  text-decoration: none;
}
